import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { QrcodeStream } from 'vue-qrcode-reader';

export default Vue.extend({
  data() {
    return {
      camera: 'off',
      reboxNumber: '',
      openScanner: false,
      showLoader: false,
    };
  },

  components: {
    ScannerForm: () => import('@/components/ui/ScannerForm.vue'),
    Scanner: () => import('@/components/ui/Scanner.vue'),
    QrcodeStream,
  },

  computed: {
    dialogWidth() {
      return window.innerWidth - 30;
    },
  },
  methods: {
    async onInit(promise: any): Promise<void> {
      try {
        this.showLoader = true;
        await promise;
      } finally {
        this.showLoader = false;
      }
    },
    scan() {
      this.openScanner = true;
    },
    onDecode(result: string) {
      this.reboxNumber = result;
      // (result.includes('/')) ? this.cutOutID(result) : result;
      this.openScanner = false;
      this.camera = 'off';
    },
    // cutOutID(scannedCode: string) {
    //   const indexOfLastSlash = scannedCode.lastIndexOf('/');
    //   return scannedCode.slice(indexOfLastSlash + 1);
    // },
  },
  watch: {
    openScanner: {
      handler(val: boolean) {
        if (val) {
          this.camera = 'auto';
        }
      },
    },
  },
});
